<template>
    <NuxtLink
        v-if="url"
        :to="url"
        :target="link?.target"
    >
        <slot>{{ link?.text }}</slot>
    </NuxtLink>
    <a v-else>
        <slot>{{ link?.text }}</slot>
    </a>
</template>

<script setup>
import { HOME_URI } from '~/enums/page-uri';

const props = defineProps({
    to: {
        type: [Object, String],
        required: false,
        default: () => null
    },
    link: {
        type: [Object, String],
        required: false,
        default: () => null
    }
});

/**
 * Given a variety of link types, return the URL to link to. Translate it, and replace any __home__ placeholders
*/
const localePath = useLocalePath();
const url = computed(() => {
    // Backwards compatible with old link prop
    const linkProp = props.to ?? props.link;
    if (!linkProp) {
        return null;
    }

    let uri = null;
    if (typeof linkProp === 'string') {
        uri = linkProp;
    }

    else if (linkProp?.element?.uri) {
        uri = linkProp.element.uri;
    }

    else if (linkProp?.url) {
        // URL is absolute, so return it unchanged
        return linkProp.url;
    }


    if (uri === HOME_URI) {
        uri = '/';
    }

    if (!uri) {
        return null;
    }

    // Strip any leading slashes
    uri = uri.replace(/^\/+/g, '');

    return localePath({
        path: `/${uri}`
    });
});
</script>
